@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;1,600&display=swap');

:root {
    --primary-color: #BA9100;
}

span {
    color: var(--primary-color);
    text-shadow: .05rem 0 .08rem #BA9100;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: white;
    text-shadow: .05rem 0 .08rem white;
    letter-spacing: .08rem;
}

body {
    margin: 0;
    font-family: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: white;
}

p,
li {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;

}

h1 {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: .5rem;
}

h1 span {
    letter-spacing: .5rem;
}

h2 {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .5rem;
}

h3 {
    font-size: 2.5rem;
}

.container {
    max-width: 1240px;
    margin: auto;
    height: 100%;
    position: relative;
}

button {
    font-family: 'Roboto', sans-serif;
    padding: .7rem 1.5rem;
    margin: 1rem 0;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    border-radius: 4px;
}

/* button:hover {
    background-color: #47dcff;
    border: 1px solid #47dcff;
} */

/* @media screen and (max-width: 940px) {
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.5rem;
    }
} */